import { Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
} from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ChatActions } from 'src/app/store/chat/chat.actions';
import { environment } from 'src/environments/environment';
import { AuthorRole } from './chat-history.service';

export type SuggestionIconType = 'LiveAgent';

export interface ISuggestion {
  Id: string;
  Type: 1 | 2;
  Value: string;
  Icon: null | SuggestionIconType;
  Order: number;
}

export enum SuggestionType {
  Flow = 1,
  Prompt = 2,
}

export interface Widget {
  Id: string;
  Type: number;
}

export interface WidgetFromHistory {
  type: number;
  id: number;
}

interface IConversationStarted {
  conversationId: string;
  question: string;
  connectorId: string;
  templateId: string;
}

export interface IEventConversationTyping {
  AggregateId: string;
  ApplicationId: string;
  Chunk: string;
  ConversationId: string;
  MessageId: string;
  Question: string;
  TimeStamp: string;
  Version: number;
}

export interface IEventConversationCompleted {
  ApplicationId: string;
  ConversationId: string;
  MessageId: string;
  UserId: string;
  ActionId: null;
  CorrelationId: null;
  Question: string;
  Answer: string | null;
  Context?: string;
  ConnectorId?: string;
  Suggestions: ISuggestion[] | null;
  Widgets?: Widget[];
  Version: number;
  TimeStamp: string;
  AggregateId: string;
}

export interface IEventUserLeaveOrJoined {
  ApplicationId: string;
  ConversationId: string;
  UserId: string;
  UserName: string;
  UserRole: AuthorRole;
  Version: string;
  TimeStamp: string;
  AggregateId: string;
}

type ConversationEventName =
  | 'ConversationCompleted'
  | 'ConversationTyping'
  | 'UserLeaveConversationEvent'
  | 'UserJoinedConversationEvent';

interface INotification {
  aggregateId: string;
  applicationId: string;
  event: string;
  eventName: ConversationEventName;
  timeStamp: string;
  userId: string;
  version: number;
}

interface INotificationDTO {
  aggregateId: string;
  applicationId: string;
  event:
    | IEventConversationTyping
    | IEventConversationCompleted
    | IEventUserLeaveOrJoined;
  eventName: ConversationEventName;
  timeStamp: string;
  userId: string;
  version: number;
}

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  constructor(
    private store: Store,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private hubConnection!: HubConnection;

  private onConnected$!: Observable<string>;
  private onCreatedRoom$!: Observable<string>;
  private onConversationStarted$!: Observable<IConversationStarted>;
  private onNotify$!: Observable<INotificationDTO>;

  createConnection(accessToken: string) {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.BACKEND_URL}/chat`, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => accessToken,
      })
      .withAutomaticReconnect()
      .build();

    return this;
  }

  private async startConnection() {
    try {
      await this.hubConnection.start();
      this.store.dispatch(ChatActions.connected());

      console.log('SignalR Connected.');
    } catch (err) {
      console.log('SignalR Error:', err);

      setTimeout(this.startConnection, 1000);
    }
  }

  start() {
    this.startConnection().then();

    this.hubConnection.onreconnected(() => {
      console.log('SignalR reconnected');

      this.store.dispatch(ChatActions.connected());
    });

    this.hubConnection.onreconnecting(() => {
      console.log('SignalR reconnecting');

      this.store.dispatch(ChatActions.disconnected());
    });

    this.hubConnection.onclose(async (err) => {
      console.log('SignalR Connection closed with error:', err);

      this.store.dispatch(ChatActions.disconnected());
      this.startConnection().then();
    });

    this.initializeListeners();

    this.keepTabActive();

    return this;
  }

  onConnected(): Observable<string> {
    return this.onConnected$;
  }

  createRoom(conversationId?: string) {
    this.hubConnection.send(
      'createRoom',
      conversationId,
      environment.APPLICATION_ID,
      environment.APPLICATION_LABEL
    );

    return this;
  }

  onCreatedRoom(): Observable<string> {
    return this.onCreatedRoom$;
  }

  onConversationStarted(): Observable<IConversationStarted> {
    return this.onConversationStarted$;
  }

  onNotify(): Observable<INotificationDTO> {
    return this.onNotify$;
  }

  sendMessage(message: {
    conversationId: string;
    question: string;
    templateId: string;
    connectorId: string;
    actionFlow: ISuggestion[];
  }) {
    this.store.dispatch(ChatActions.resetSuggestions());

    const actionFlowIdsOrNull = message.actionFlow.length
      ? message.actionFlow.map((flow) => flow.Id)
      : null;

    return this.hubConnection.send('conversationStarted', {
      conversationId: message.conversationId,
      question: message.question,
      connectorId: message.connectorId,
      templateId: message.templateId,
      flowIds: actionFlowIdsOrNull,
      applicationId: environment.APPLICATION_ID,
      applicationLabel: environment.APPLICATION_LABEL,
    });
  }

  private initializeListeners() {
    this.onConnected$ = new Observable((observer) => {
      this.hubConnection.on('onConnect', () => observer.next());
    });

    this.onCreatedRoom$ = new Observable((observer) => {
      this.hubConnection.on('onCreateRoom', (roomId: string) => {
        observer.next(roomId);
      });
    });

    this.onConversationStarted$ = new Observable((observer) => {
      this.hubConnection.on('onConversationStarted', (msg) => {
        observer.next(msg);
      });
    });

    this.onNotify$ = new Observable((observer) => {
      this.hubConnection.on('onNotify', (notification: INotification) => {
        observer.next({
          ...notification,
          event: JSON.parse(notification.event),
        } as INotificationDTO);
      });
    });

    this.onError();
  }

  private onError() {
    this.hubConnection.on('onError', () => {
      this.showErrorToastr();
      this.store.dispatch(ChatActions.conversationWithError());
    });
  }

  private showErrorToastr() {
    this.translate
      .get('CORE.API.SOCKET.TOASTR')
      .subscribe(
        (toastr: { ERROR_TITLE: string; ERROR_DESCRIPTION: string }) => {
          this.toastr.error(toastr.ERROR_TITLE, toastr.ERROR_DESCRIPTION, {
            progressBar: true,
            closeButton: true,
          });
        }
      );
  }

  private keepTabActive() {
    // This blocks the browser from making the user tab inactive, thus affecting the websocket connection
    // For info, see: https://learn.microsoft.com/pt-br/aspnet/core/signalr/javascript-client?view=aspnetcore-8.0&tabs=visual-studio#bsleep
    let lockResolver;

    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise((res) => {
        lockResolver = res;
      });

      const unique_lock_name = new Date().toString();

      navigator.locks.request(unique_lock_name, { mode: 'shared' }, () => {
        return promise;
      });
    }
  }
}
